<template>
  <b-card class="p-2 p-lg-4 w-100">
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Вход
    </b-card-title>

    <!-- form -->
    <b-form
      class="auth-login-form mt-2"
      @submit.prevent
    >
      <!-- email -->
      <b-form-group
        label="Email"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="email"
          v-validate="'required|email'"
          :state="email === '' ? null : !errors.has('email')"
          name="email"
          placeholder="john@example.com"
        />
        <small
          v-show="errors.has('email')"
          class="text-danger"
        >{{ errors.first('email') }}</small>
      </b-form-group>

      <!-- password -->
      <input-password
        v-model="password"
        v-validate="'required|min:8'"
        label="Пароль"
        name="password"
        :state="password === '' ? null : !errors.has('password')"
        :error="errors.first('password')"
      />

      <!-- checkbox -->
      <div class="d-flex justify-content-between mt-3">
        <div class="">
          <b-form-group>
            <b-form-checkbox
              id="remember-me"
              v-model="remember"
              name="checkbox-1"
            >
              Запомнить меня
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="">
          <b-link :to="{name: 'recovery'}">
            <span>Забыли пароль?</span>
          </b-link>
        </div>
      </div>

      <div class="d-flex align-items-center mt-1 justify-content-end">
        <div class="">
          <!-- submit buttons -->
          <b-button
            class="w-auto"
            type="submit"
            variant="primary"
            block
            @click="sendForm"
          >
            Войти
          </b-button>
        </div>
      </div>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BLink, BFormGroup, BFormInput, BFormCheckbox, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'
import Auth from '@/api/http/models/auth/Auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCardTitle,
    BForm,
    BButton,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      email: '',
      password: '',
      remember: true,
    }
  },
  computed: {
    ...mapGetters({
      userIsVerified: 'user/isEmailVerified',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions({
      fetchCurrentUser: 'user/FETCH_CURRENT_USER',
    }),
    async sendForm() {
      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Auth.login({
          email: this.email,
          password: this.password,
          remember: this.remember,
        })

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
        }

        if (response.status === 'success') {
          await this.fetchCurrentUser()

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Успешный вход',
              icon: 'EditIcon',
              variant: 'success',
            },
          })

          if (this.userIsVerified) {
            document.location = '/'
          } else {
            this.$router.push({ name: 'need-verify' })
          }
        }
      }
    },
  },
}
</script>
